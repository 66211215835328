<!--
 * @Author: ttheitao
 * @Description: 子表单管理
 * @Date: 2022-08-16 16:44:52
 * @LastEditors: zx
 * @LastEditTime: 2022-10-24 11:53:46
-->
<template>
  <div class="sub-forms-container">
    <header>
      <div>
        <el-button type="default" size="small" class="button-class" @click="$router.back()">
          <i class="el-icon-arrow-left"></i> 返回
        </el-button>
      </div>
      <div>
        <el-button type="primary" size="small" class="button-class" @click="openAddForm">
          新增子表单
        </el-button>
      </div>
    </header>
    <el-table v-loading="loading" :data="tableData" border style="overflow: auto;">
      <el-table-column prop="name" label="子表单名称"> </el-table-column>
      <el-table-column prop="rules" label="子表单条件（修改实时生效）" min-width="300">
        <template slot-scope="item">
          <div class="rules-box">
            <el-form class="main">
              <treeForm
                :showTitle="showTitle"
                :obuuid="obuuid"
                :dictionaryList="dictionaryList"
                :relationshipList="relationshipList"
                :formData="item.row.rules && item.row.rules !== null ? [item.row.rules] : null"
                :fieldList="fieldList"
                @input="setRules($event, item)"
              ></treeForm>
            </el-form>
            <el-button type="primary" class="btn" size="mini" @click="updateRules(item)">
              保存
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建日期" width="200"> </el-table-column>
      <el-table-column align="right" width="200" label="操作">
        <template slot-scope="item">
          <span class="operation-primary" @click="handleEdit(item.row)">编辑</span>
          <span class="operation-primary" @click="handleDesign(item.row)">设计</span>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(item)" style="margin-left: 5px">
            <span class="operation-del" slot="reference">删除</span>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-box">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      v-if="addFormVisible"
      :title="'表单信息' + (addData.uuid ? ' uuid： ' + addData.uuid : '')"
      :visible.sync="addFormVisible"
      width="400px"
    >
      <el-form label-width="120px" label-position="top">
        <el-form-item label="表单名称">
          <el-input v-model="addData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开启流程">
          <el-switch v-model="addData.need_flow"></el-switch>
        </el-form-item>
        <el-form-item label="使用上级流程">
          <el-switch v-model="addData.use_parent_flow"></el-switch>
        </el-form-item>
        <!-- <el-form-item label="默认使用的视图">
          <ViewSelect :object_uuid="$route.query.obuuid" v-model="addData.use_view_uuid"></ViewSelect>
        </el-form-item> -->
        <el-form-item label="标识名称">
          <strong>用于外部调用定位</strong>
          <el-select
            v-model="addData.use_tag_name"
            placeholder="请选择"
            multiple
            filterable
            allow-create
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in usageTags"
              :key="index"
              :value="item.value"
              :label="item.label"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveItem">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增表单弹窗 -->
    <el-dialog
      title="新增子表单"
      :visible.sync="showAddChildForm"
      width="400px"
    >
      <el-form label-width="80px" label-position="top">
        <el-form-item label="表单名称">
          <el-input v-model="addFormName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddChildForm = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import objects from '@/api/objects';
import treeForm from '@/views/objects/components/treeForm.vue';
import objectMeta from '@/global/mixins/objectMeta.js';
import { Popconfirm } from 'element-ui';
import { mapState } from 'vuex';

export default {
  components: {
    treeForm,
    'el-popconfirm': Popconfirm,
  },
  mixins: [objectMeta],
  data() {
    return {
      obuuid: '',
      tableData: [],
      currentPage: 1,
      size: 14,
      total: 0,
      loading: false,
      isEdit: false,
      formDesignData: null,
      displayData: null,
      parent_uuid: '',
      formDesign: null,
      showTitle: false,
      addFormVisible: false,
      addData: {
        id: 0,
        name: '',
        need_flow: 0,
        use_parent_flow: 0,
        use_view_uuid: '',
        use_tag_name: '',
        uuid: '',
      },
      addFormName: '',
      showAddChildForm: false
    };
  },
  computed: {
    ...mapState({
      usageTags: (state) => state.dataObjects.usageTags || null,
    }),
  },
  created() {
    this.obuuid = this.$route.query?.obuuid || '';
    this.parent_uuid = this.$route.query?.uuid || '';

    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      objects
        .getSubFormList(this.parent_uuid)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    /**
     * @description: 数打开表单新增
     * @param {*}
     * @return {*}
     */
    openAddForm() {
      this.addFormName = ''
      this.showAddChildForm = true
    },
    add() {
      if (!this.addFormName) {
        return this.$message.warning('请填写表单名称！')
      }
      this.loading = true;
      this.initFormDesign().then(() => {
        this.loading = true;
        objects
          .saveObjectDesign({
            name: this.addFormName,
            object_uuid: this.obuuid,
            form_json: JSON.stringify(this.formDesignData),
            parent_uuid: this.parent_uuid,
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.showAddChildForm = false;
              this.$nextTick(() => {
                this.getData();
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.showAddChildForm = false;
          });
      });
    },
    /**
     * @description: 初始化表单配置数据 以及编辑时的回显数据
     * @param {String} uuid  选择的表单uuid
     * @return {*} Promise
     */
    initFormDesign(uuid = '') {
      return new Promise((resolve, reject) => {
        objects.transFieldsUUid(uuid || this.obuuid).then((res) => {
          if (res.data.code == 200) {
            if (this.isEdit) {
              // 获取已经有的数据进行绑定
              let config = res.data.data;
              let ruuid = [];
              for (let i = 0; i < config.fields.length; i++) {
                let item = config.fields[i];
                if (item.__config__.tagIcon == 'form') {
                  ruuid.push({ relationship_uuid: item.__vModel__ });
                }
              }
              objects.mapi.getDetail(this.obuuid, this.data_id, '', ruuid).then((res1) => {
                let data = res1.data.data;
                this.formDesignData = res.data.data;
                this.displayData = data;
                this.loading = false;
                resolve(true);
              });
            } else {
              this.formDesignData = res.data.data;
              this.loading = false;
              resolve(true);
            }
          } else {
            reject();
          }
        });
      });
    },
    /**
     * @description: 监听条件组值改变
     * @param {*} rules
     * @param {*} index
     * @return {*}
     */
    onTreeFormChange(rules, index) {
      console.log(rules, index);
    },
    handleEdit(item) {
      console.log(item);
      if (item.name == '' || item.name == 'default' || !item.uuid) {
        this.$message.error('默认表单不可更改');
        return;
      }
      if (typeof item.use_tag_name == 'string') {
        if (item.use_tag_name.indexOf(',') > -1) {
          item.use_tag_name = item.use_tag_name.split(',');
        } else {
          item.use_tag_name = [item.use_tag_name];
        }
      } else if (!item.use_tag_name) {
        item.use_tag_name = null;
      }
      this.addData.name = item.name;
      this.addData.need_flow = item.need_flow ? true : false;
      this.addData.use_parent_flow = item.use_parent_flow ? true : false;
      this.addData.id = item.id;
      this.addData.use_tag_name = item.use_tag_name;
      this.addData.uuid = item.uuid;
      this.addFormVisible = true;
      return;
    },
    /**
     * @description: 设计表单
     * @param {*} item
     * @return {*}
     */
    handleDesign(item) {
      let needFlow = !!item.need_flow;
      this.$router.push({
        path:
          '/dwh/objects/design-form?obuuid=' +
          this.obuuid +
          '&obname=' +
          this.$route.query.obname +
          '&type=field&uuid=' +
          item.uuid +
          '&name=' +
          item.name +
          '&needFlow=' +
          needFlow +
          '&parent_uuid=' +
          item.parent_uuid,
      });
    },
    /**
     * @description: 处理删除
     * @param {*} item
     * @return {*}
     */
    handleDel(item) {
      this.$confirm('确认删除吗？所有的引用都将失效', '提示')
        .then((res) => {
          if (res !== 'confirm') {
            return;
          }
          this.loading = true;
          objects.deleteForm(item.row.id).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    /**
     * @description: 设置表单规则
     * @param {*} rules
     * @param {*} item
     * @return {*}
     */
    setRules(rules, item) {
      item.row.rules = rules;
    },
    /**
     * @description: 保存表单规则
     * @param {*} item
     * @return {*}
     */
    updateRules(item) {
      this.loading = true;
      objects
        .updateFormRules(item.row.uuid, { rules: item.row.rules })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success('保存成功');
          } else {
            this.$message.error('保存失败');
          }

          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.$message.error('保存失败');
          this.loading = false;
        });
    },
    /**
     * @description: 保存编辑数据
     * @param {*}
     * @return {*}
     */
    saveItem() {
      if (!this.addData.name) {
        this.$message.error('名称不能为空！');
        return;
      }
      if (this.addData.name == 'default') {
        this.$message.error('不可命名为保留名称');
        return;
      }
      // 验证use_tag_name是否重复，已经必然是一个数组了，嵌套循环即可

      for (let i = 0; i < this.addData.use_tag_name.length; i++) {
        const tag_name = this.addData.use_tag_name[i];
        for (let j = 0; j < this.tableData.length; j++) {
          const item = this.tableData[j];
          if (item.uuid !== this.addData.uuid) {
            if (item.use_tag_name && item.use_tag_name.indexOf(tag_name) > -1) {
              this.$message.error(
                '已存在 【' + this.getTagName(tag_name) + '】 标签的表单：' + item.name
              );
              return;
            }
          }
        }
      }

      let loading = this.$loading();

      this.addData.use_tag_name = this.addData.use_tag_name.join(',');
      objects
        .editFormName(this.addData.id, this.addData)
        .then((res) => {
          loading.close();
          if (res.data.code == 200) {
            this.addFormVisible = false;
            this.addData = {
              id: 0,
              name: '',
              need_flow: 0,
              use_parent_flow: 0,
              use_view_uuid: '',
              use_tag_name: '',
              uuid: '',
            };
            this.getData();
          }
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../manage-views/css/manageAdd.less";
.sub-forms-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .pagination-box {
    margin-top: 10px;
    display: flex;
    justify-content: right;
  }

  .rules-box {
    position: relative;
    .btn {
      position: absolute;
      right: 75px;
      top: 19px;
    }
  }
}
</style>
