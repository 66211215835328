import object from "@/api/objects";

export default {
  data() {
    return {
      fieldList: [], // 字段数据
      dictionaryList: [], //字典数据
      relationshipList: [], // 关联关系
      filterType: object.filterType, // 比较类型
    }
  },
  mounted() {
    if (this.$store.state.dataObjects.valueFormDatas == "") {
      this.getSelectList();
    } else {
      let valueFormDatas = JSON.parse(
        this.$store.state.dataObjects.valueFormDatas
      );
      if (valueFormDatas.obuuid && valueFormDatas.obuuid != this.obuuid) {
        this.getSelectList();
      } else {
        this.dictionaryList = valueFormDatas.dictionaryList;
        this.fieldList = valueFormDatas.fieldList;
        this.relationshipList = valueFormDatas.relationshipList;
      }
    }
  },
  methods: {
    getSelectList() {
      if (this.dictionaryList.length == 0) {
        object.getDicts(this.obuuid).then((res) => {
          if (res.data.code == 200) {
            let obj = { label: "普通字典", options: res.data.data };
            this.dictionaryList.push(obj);
            object.getDicts("global").then((res1) => {
              if (
                res1.data.code == 200 &&
                res1.data.data &&
                res1.data.data.length != 0
              ) {
                let obj1 = { label: "全局字典", options: res1.data.data };
                this.dictionaryList.unshift(obj1);
              }
              this.$store.commit(
                "setValueFormDatas",
                JSON.stringify({
                  obuuid: this.obuuid,
                  dictionaryList: this.dictionaryList,
                  fieldList: this.fieldList,
                  relationshipList: this.relationshipList,
                })
              );
            });
          }
        });
      }
      if (this.fieldList.length == 0) {
        object.getFields(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.fieldList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
      if (this.relationshipList.length == 0) {
        object.getMyRelations(this.obuuid).then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data.length != 0
          ) {
            this.relationshipList = res.data.data.data;
            this.$store.commit(
              "setValueFormDatas",
              JSON.stringify({
                obuuid: this.obuuid,
                dictionaryList: this.dictionaryList,
                fieldList: this.fieldList,
                relationshipList: this.relationshipList,
              })
            );
          }
        });
      }
    }
  }
}